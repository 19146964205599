import { PermissionsApiUrl as appDataApiUrl } from './components/apiUrls';
import headerLogo from './admin-header-logo.png';
import { stdlibApp } from './stdlib/stdlib-app';
//import { IRoutes } from './stdlib/stdlib-models';
import React from 'react';
import { PortalRouterPageObj } from './stdlib/stdlib-appmodels';
import { AdminAppData } from './components/models';



const DashPage = React.lazy(() => import('./pages/DashPage'));
const AdminContainerPage = React.lazy(() => import('./pages/admin/AdminContainerPage'));
const AccountSettingsPage = React.lazy(() => import('./pages/AccountSettingsPage'));
const SignOutPage = React.lazy(() => import('./pages/SignOutPage'));
const BusinessIndexPage = React.lazy(() => import('./pages/business/BusinessIndexPage'));
const AnalyticsContainerPage = React.lazy(() => import('./pages/analytics/AnalyticsContainerPage'));

const routes: PortalRouterPageObj<AdminAppData>[] = [
    { path: '/', exact: true, name: 'Dashboard', component: DashPage, shouldCloseMenu: true },
    { path: '/account-settings', name: 'Account Settings', component: AccountSettingsPage, shouldCloseMenu: true },
    { path: '/b', name: 'Business', component: BusinessIndexPage, shouldCloseMenu: false },
    { path: '/analytics', name: 'Analytics', component: AnalyticsContainerPage, shouldCloseMenu: false },
    { path: '/admin', name: 'Admin', component: AdminContainerPage, shouldCloseMenu: false },
    { path: '/sign-out', name: 'Sign Out', component: SignOutPage, shouldCloseMenu: true },
];



export interface AdminPermissions {
    hasAdminPermission: boolean;
}

const routeFilterFunc = (appData: AdminAppData, route: PortalRouterPageObj<AdminAppData,{}, {}>) => {
    return true;
}

export default stdlibApp<AdminAppData>({ appDataApiUrl, routes, routeFilterFunc, headerLogo, defaultTitle: "Book.tc Admin" })

