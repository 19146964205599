import { Guid } from "../stdlib/stdlib-models";

export const DashApiUrl = "/api/dashboard";
export const PermissionsApiUrl = "/api/dashboard/permissions";
export const AccountSettingsApi = "/api/account-settings";
export const SignOutHereApiUrl = "/api/sign-out";
export const SignOutEverywhereApiUrl = "/api/sign-out-everywhere";

export const SystemLogApiUrl = "/api/log";
export const AdminUserSettingsApi: (userGuid: Guid) => Guid = userGuid => `/api/admin-users/${userGuid}`;
export const AdminUserSummaryApi: (userGuid: Guid) => Guid = userGuid => `/api/admin-users/${userGuid}/summary`;
export const AdminUserDeleteApi: (userGuid: Guid) => Guid = userGuid => `/api/admin-users/${userGuid}/delete`;
export const AdminUserResetPasswordApi: (userGuid: Guid) => Guid = userGuid => `/api/admin-users/${userGuid}/resetpassword`;
export const AdminUserDeleteTotpApi: (userGuid: Guid) => Guid = userGuid => `/api/admin-users/${userGuid}/removetotp`;
export const ListAdminUsersApiUrl = "/api/admin-users";
export const NewAdminUserApiUrl = "/api/admin-users/new";


export const BusinessSummaryApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/summary`;
export const BusinessBasicsApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/basics`;
export const BusinessOperatorProfileApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/operatorprofile`;
export const BusinessCustomBookingMessagesApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/custombookingmessages`;
export const DeleteBusinessApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/delete`;


export const NewBusinessApi = "/api/business/new";
export const BusinessIndexApi: Guid = "/api/business";


export const AddAssetApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newasset`;
export const ListAssetsApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/assets`;
export const DeleteAssetApi: (businessGuid: Guid, assetGuid: Guid) => Guid = (businessGuid, assetGuid) => `/api/business/${businessGuid}/asset/${assetGuid}/delete`;
export const AssetApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, assetGuid) => `/api/business/${businessGuid}/asset/${assetGuid}`;

//DeleteProductApi, ProductApi, ListProductsApi, AddProductApi

export const ListProductsApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/products`;
export const ProductSummaryApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/summary`;
export const AddProductApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newproduct`;
export const DeleteProductApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/delete`;
export const ProductBasicsApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/basics`;
export const ProductScheduleApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/schedule`;
export const ProductProfileApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/profile`;
export const ProductPromotionsApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/promotions`;
export const ProductPhotosApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/photos`;
export const ProductMessagesApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/messages`;
export const ResetProductCalendarApi: (businessGuid: Guid, productGuid: Guid) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/product/${productGuid}/resetcalendar`;

//DeleteBusinessUserApi, BusinessUserApi, ListBusinessUsersApi, AddBusinessUserApi 

export const ListBusinessUsersApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/users`;
export const AddBusinessUserApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newuser`;
export const DeleteBusinessUserApi: (businessGuid: Guid, userGuid: Guid) => Guid = (businessGuid, userGuid) => `/api/business/${businessGuid}/user/${userGuid}/delete`;
export const BusinessUserApi: (businessGuid: Guid, userGuid: Guid) => Guid = (businessGuid, userGuid) => `/api/business/${businessGuid}/user/${userGuid}`;
export const BusinessUserResetPasswordApi: (businessGuid: Guid, userGuid: Guid) => Guid = (businessGuid, userGuid) => `/api/business/${businessGuid}/user/${userGuid}/resetpassword`;
export const BusinessUserDeleteTotpApi: (businessGuid: Guid, userGuid: Guid) => Guid = (businessGuid, userGuid) => `/api/business/${businessGuid}/user/${userGuid}/removetotp`;

//DeleteAssetTypeApi, AssetTypeApi, ListAssetTypesApi, AddAssetTypeApi

export const ListAssetTypesApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/assettypes`;
export const AddAssetTypeApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newassettype`;
export const DeleteAssetTypeApi: (businessGuid: Guid, assetTypeGuid: Guid) => Guid = (businessGuid, assetTypeGuid) => `/api/business/${businessGuid}/asset-type/${assetTypeGuid}/delete`;
export const AssetTypeApi: (businessGuid: Guid, assetTypeGuid: Guid) => Guid = (businessGuid, assetTypeGuid) => `/api/business/${businessGuid}/asset-type/${assetTypeGuid}`;

export const ListReferralCodesApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/referralcodes`;
export const AddReferralCodeApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newreferralcode`;
export const GetNewReferralCodeStateApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newreferralcodestate`;
export const DeleteReferralCodeApi: (businessGuid: Guid, referralCodeGuid: Guid) => Guid = (businessGuid, referralCodeGuid) => `/api/business/${businessGuid}/referral-code/${referralCodeGuid}/delete`;
export const ReferralCodeApi: (businessGuid: Guid, referralCodeGuid: Guid) => Guid = (businessGuid, referralCodeGuid) => `/api/business/${businessGuid}/referral-code/${referralCodeGuid}`;


export const ListAffiliateConfigsApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/affiliateconfigs`;
export const AddAffiliateConfigApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newaffiliateconfig`;
export const GetNewAffiliateConfigStateApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newaffiliateconfigstate`;
export const DeleteAffiliateConfigApi: (businessGuid: Guid, affiliateBusinessGuid: Guid) => Guid = (businessGuid, affiliateBusinessGuid) => `/api/business/${businessGuid}/affiliates/${affiliateBusinessGuid}/delete`;
export const AffiliateConfigApi: (businessGuid: Guid, affiliateBusinessGuid: Guid) => Guid = (businessGuid, affiliateBusinessGuid) => `/api/business/${businessGuid}/affiliates/${affiliateBusinessGuid}`;


export const OpWeeklyCalendarApiUrl: (businessGuid: Guid, startDate: Date) => Guid = (businessGuid, startDate) => `/api/business/${businessGuid}/calendar?startDate=${startDate.toISOString()}&period=week`;
export const OpMonthlyCalendarApiUrl: (businessGuid: Guid, startDate: Date) => Guid = (businessGuid, startDate) => `/api/business/${businessGuid}/calendar?startDate=${startDate.toISOString()}&period=month`;


export const OpUpcomingBookingsApiUrl: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/upcomingbookings`;
export const OpPastBookingsApiUrl: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/pastbookings`;


//export const ListPageViewsApi = "/api/pageviews";

export const UploadPageStateApi = (businessGuid: string, productGuid: string) => `/api/business/${businessGuid}/uploads${productGuid ? `?productGuid=${productGuid}` : ""}`;

const MakeUploadUrl = (action: string, businessGuid: string, productGuid: string, key: string) => `/api/upload/${businessGuid}/${action}?key=${key}&${productGuid ? `productGuid=${productGuid}` : ""}`;
export const DeleteGalleryPhotoApi = (businessGuid: string, photoGuid: string) => `/api/upload/${businessGuid}/deletegallery?photoGuid=${photoGuid}`;
export const GalleryPhotoMetaApi = (businessGuid: string, photoGuid: string) => `/api/upload/${businessGuid}/galleryphotometa?photoGuid=${photoGuid}`;
export const UploadApi = (businessGuid: string, productGuid: string, key: string) => MakeUploadUrl("upload", businessGuid, productGuid, key);
export const SinglePhotoMetaApi = (businessGuid: string, productGuid: string, key: string) => MakeUploadUrl("singlephotometa", businessGuid, productGuid, key);

export const DeleteUploadApi = (businessGuid: string, productGuid: string, key: string) => MakeUploadUrl("delete", businessGuid, productGuid, key);


export const ListPromotionsApiUrl: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/promotions`;
export const AddPromotionApiUrl: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/addpromotion`;
export const DeletePromotionApiUrl: (businessGuid: Guid, promotionGuid: Guid) => Guid = (businessGuid,promotionGuid) => `/api/business/${businessGuid}/promotion/${promotionGuid}/delete`;
export const PromotionApiUrl: (businessGuid: Guid, promotionGuid: Guid) => Guid = (businessGuid, promotionGuid) => `/api/business/${businessGuid}/promotion/${promotionGuid}`;


export const OpNewBookingStateApiUrl: (businessGuid: Guid, date: string) => Guid = (businessGuid,date) => `/api/business/${businessGuid}/newbookingstate?date=${date}`;
export const OpNewProductBookingApiUrl: (businessGuid: Guid, productGuid: string) => Guid = (businessGuid, productGuid) => `/api/business/${businessGuid}/newproductbooking?productGuid=${productGuid}`;
export const OpNewCustomBookingApiUrl: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/newcustombooking`;
export const OpGetBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}`;
export const OpConfirmBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/confirm`;
export const OpCancelBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/cancel`;
export const OpDeleteBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/delete`;
export const OpCheckInBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/checkin`;
export const OpSignWaiverBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/signwaiver`;
export const OpBookingCardPaymentApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/cardpayment`;
export const OpBookingOtherPaymentApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/otherpayment`;
export const OpMoveBookingDateStateApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/movedatestate`;
export const OpMoveBookingAssetStateApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/moveassetstate`;
export const OpMoveBookingDateApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/movedate`;
export const OpMoveBookingAssetApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/moveasset`;
export const OpEditBookingApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/edit`;
export const OpChangeBookingGuestsApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/changeguests`;
export const OpBookingAnalyticsApiUrl: (businessGuid: Guid, bookingGuid: Guid) => Guid = (businessGuid, bookingGuid) => `/api/business/${businessGuid}/booking/${bookingGuid}/analytics`;

export const BlocksApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/blocks`;
export const AddBlockApi: (businessGuid: Guid) => Guid = businessGuid => `/api/business/${businessGuid}/addblock`;
export const DeleteBlockApi: (businessGuid: Guid, blockGuid: Guid) => Guid = (businessGuid, blockGuid) => `/api/business/${businessGuid}/block/${blockGuid}/delete`;
export const GetBlockApi: (businessGuid: Guid, blockGuid: Guid) => Guid = (businessGuid, blockGuid) => `/api/business/${businessGuid}/block/${blockGuid}`;

export const AnalyticsSessionsApiUrl = "/api/sessions";
export const AnalyticsDateReportApiUrl : (rangeKey: string, startDate: string) => string  = (rangeKey,startDate)=> `/api/analyticsreport?range=${rangeKey}&date=${startDate}`;

